let key = {};

let toasterOption = {
  position: "top-right",
  autoClose: 8000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
};
let Envname = "demo";

if (Envname === "prodction") {
  console.log("Live Config");
  key = {
    frontend: "https://www.unityfund.io",
    polyfundcontract: "0x4F0CBeAb4093dD8bBC5D8D6616B64186C7F47528", //live
    polyfundcontractOld: "0xBA7559285E922Deb26ED01C5c820Eb5059a6B5c4",
    USDTContractAddress: "0x55d398326f99059fF775485246999027B3197955",
    adminaddress: "0xF24362C2be0E2d397d0fb7D5fb4269A2DBd0b8B2",
    ownerWallet: "0xF24362C2be0E2d397d0fb7D5fb4269A2DBd0b8B2",
    toasterOption: toasterOption,
    NetworkId: 56,
    decimals: 1e18,
    rpcUrl: "https://bsc-rpc.gateway.pokt.network",
    dataUrl: "https://bsc-rpc.gateway.pokt.network",
    scan: "https://bscscan.com/",
    apiData:
      "https://api.bscscan.com/api?module=account&action=txlist&address=0x4F0CBeAb4093dD8bBC5D8D6616B64186C7F47528&sort=desc&apikey=FH7AQA2ES1JFBF979QCZRSU9AX87ZM5MRN",
    apiDataSorted:
      "https://api.bscscan.com/api?module=account&action=txlist&address=0x4F0CBeAb4093dD8bBC5D8D6616B64186C7F47528&page=1&offset=4&sort=desc&apikey=FH7AQA2ES1JFBF979QCZRSU9AX87ZM5MRN"
  };
} else if (Envname === "demo") {
  console.log("Set demo Config");
  key = {
    frontend: "https://unityfund-frontend.pages.dev",
    polyfundcontractOld: "0x31667Cf6DEfB0cDb613c0d8bCbC311dC56017376",
    polyfundcontract: "0xdea817CCD6230C7be9b1b8f5a6842dc77942B7e1", // Old - 0x95b61306100e9E92c9dE02Cc90501DF5a769C819 , 0xDe77E1594D31795f922957564493cE60A015B319
    USDTContractAddress: "0x8CE7720fD183AeC96b676FD8250724b05b0d7a6F",
    adminaddress: "0x80Aa595c83430288E3A75F4D03bE15b72385420F",
    ownerWallet: "0x373F886904048975034488320E37d171E092C135",
    toasterOption: toasterOption,
    NetworkId: 97,
    decimals: 1e18,
    rpcUrl: "https://bsc-testnet.publicnode.com",
    dataUrl: "https://bsc-testnet.publicnode.com",
    scan: "https://testnet.bscscan.com/",
    apiData:
      "https://api-testnet.bscscan.com/api?module=account&action=txlist&address=0xdea817CCD6230C7be9b1b8f5a6842dc77942B7e1&sort=desc&apikey=FH7AQA2ES1JFBF979QCZRSU9AX87ZM5MRN",
    apiDataSorted:
      "https://api-testnet.bscscan.com/api?module=account&action=txlist&address=0xdea817CCD6230C7be9b1b8f5a6842dc77942B7e1&page=1&offset=4&sort=desc&apikey=FH7AQA2ES1JFBF979QCZRSU9AX87ZM5MRN"
  };
} else {
  console.log("Set Development Config");
  key = {
    frontend: "http://localhost:3000",
    polyfundcontract: "0xdea817CCD6230C7be9b1b8f5a6842dc77942B7e1", //local
    polyfundcontractOld: "0x0E39EF8f3Eb94A958766989aA7d9B231fa197023",
    USDTContractAddress: "0x8CE7720fD183AeC96b676FD8250724b05b0d7a6F",
    adminaddress: "0x80Aa595c83430288E3A75F4D03bE15b72385420F",
    ownerWallet: "0x373F886904048975034488320E37d171E092C135",
    toasterOption: toasterOption,
    NetworkId: 97,
    decimals: 1e18,
    rpcUrl: "https://data-seed-prebsc-2-s2.bnbchain.org:8545",
    dataUrl: "https://data-seed-prebsc-1-s1.binance.org:8545",
    scan: "https://testnet.bscscan.com/",
    apiData:
      "https://api-testnet.bscscan.com/api?module=account&action=txlist&address=0xdea817CCD6230C7be9b1b8f5a6842dc77942B7e1&sort=desc&apikey=FH7AQA2ES1JFBF979QCZRSU9AX87ZM5MRN",
    apiDataSorted:
      "https://api-testnet.bscscan.com/api?module=account&action=txlist&address=0xdea817CCD6230C7be9b1b8f5a6842dc77942B7e1&page=1&offset=4&sort=desc&apikey=FH7AQA2ES1JFBF979QCZRSU9AX87ZM5MRN"
  };
}

export default key;
