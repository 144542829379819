import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { Scrollbars } from "react-custom-scrollbars";
import Web3 from "web3";
import config from "../lib/config";
import moment from "moment";
import { toast } from "react-toastify";
import { connection } from "../helper/connection";
import copy from "copy-to-clipboard";
import axios from "axios";
import { useSelector } from "react-redux";
import polyfundcontractabi from "../ABI/polyfundcontractabi.json";
import polyfundcontractabiOld from "../ABI/polyfundcontractabiold.json";
import usdtcontractABI from "../ABI/usdtcontractABI.json";
import { Multicall } from "ethereum-multicall";
import syncEach from "sync-each";
import BigNumber from "bignumber.js";
import Spinner from "react-bootstrap/Spinner";
import abiDecoder from "abi-decoder";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Countdown, { zeroPad } from "react-countdown";

import {
  getTreeImage,
  firstTree,
  secondTree,
  thirdTree,
  fourthTree
} from "./treeinfo";

export default function Dashboard(props) {
  let toasterOption = config.toasterOption;
  const walletConnection = useSelector((state) => state.walletConnection);
  const [referaladdress, setreferaladdress] = useState("");
  const [progress, setprogress] = useState(false);
  const [progress1, setprogress1] = useState(false);
  const [progress3, setprogress3] = useState(false);
  const [treeprogress, settreeprogress] = useState(false);
  const [userdetails, setuserdetails] = useState({});
  const [userdetailsOld, setuserdetailsOld] = useState({});
  const [selected, setSelected] = useState({});
  const [searchid, setsearchid] = useState("");
  const [alltreedata, setalltreedata] = useState({});
  const [roiBal, setRoiBal] = useState(0);
  const [joinAmt, setJoinAmt] = useState(0);
  const [latesttrx, setlatesttrx] = useState([]);
  const [depoHisLoad, setDepositHisLoad] = useState(false);
  const [userdetail1, setuserdetail1] = useState([]);
  const [userdetail2, setuserdetail2] = useState([]);
  const [userdetail3, setuserdetail3] = useState([]);
  const [userdetail4, setuserdetail4] = useState([]);
  const [firstRow, setfirstRow] = React.useState([]);
  const [secondRow, setsecondRow] = React.useState([]);
  const [thirdRow, setthirdRow] = React.useState([]);
  const [forthRow, setforthRow] = React.useState([]);
  const [rootInfo, setrootInfo] = useState({ address: "", id: 0 });
  const [istree, setistree] = React.useState(false);

  const [ROIStartDate, SetROIStartDate] = useState([]);
  const [ROIEndDate, SetROIEndDate] = useState([]);

  useEffect(() => {
    loadTree(walletConnection.address);
  }, [walletConnection.address]);

  useEffect(() => {
    getdetails();
  }, [walletConnection.address]);

  useEffect(() => {
    getrecentactivity();
  }, [walletConnection.address, userdetails]);

  useEffect(() => {
    getCurrId("");
  }, [userdetails]);

  useEffect(() => {
    if (searchid == "") {
      getdetails();
    }
  }, [searchid]);

  useEffect(() => {
    if (selected) {
      setDatas();
    }
  }, [selected]);

  async function loadTree(addr) {
    if (walletConnection.address) {
      settreeprogress(true);
      setistree(true);
      if (addr && addr !== "") {
        var index = userdetail1.findIndex(
          (val) =>
            val.address && val.address.toLowerCase() === addr.toLowerCase()
        );

        if (index !== -1) {
          setrootInfo({
            id: userdetail1[index].id
          });
        }
      }

      var isfirstloop = false;
      let { tree1, isfirst, user1 } = await firstTree(addr);
      console.log(user1, "user1");
      isfirstloop = isfirst;
      setuserdetail1(user1);
      setfirstRow(tree1);

      var tree2 = [];
      var issecondloop = false;
      if (tree1 && tree1.length > 0) {
        var { tree2, issecond, user2 } = await secondTree(tree1, isfirstloop);

        issecondloop = issecond;
        setuserdetail2(user2);
        setsecondRow(tree2);
      }
      var tree3 = [];
      var isthirdloop = false;
      if (tree2 && tree2.length > 0) {
        var { tree3, isthird, user3 } = await thirdTree(tree2, issecondloop);

        isthirdloop = isthird;
        if (tree3 && tree3.length > 0) {
          setuserdetail3(user3);
          const mergedArray = tree3.reduce((result, obj) => {
            return result.concat(obj.list);
          }, []);

          setthirdRow(mergedArray, isthird);
        }
      }

      if (tree3 && tree3.length > 0) {
        var { tree4, isfourth, user4 } = await fourthTree(tree3, isthirdloop);

        if (tree4 && tree4.length > 0) {
          setuserdetail4(user4);
          const mergedArray = [];
          for (const innerArray of tree4) {
            if (innerArray.length > 0) {
              for (const obj of innerArray) {
                mergedArray.push(...obj?.list);
              }
            } else {
              mergedArray.push(...innerArray?.list);
            }
          }
          setforthRow(mergedArray, isfourth);
        }
      }

      setistree(false);
      settreeprogress(false);
    }
  }

  async function setDatas(addr) {}
  async function getCurrId(addr) {
    if (addr) {
      let web3call = new Web3(
        walletConnection &&
        walletConnection.provider &&
        walletConnection.provider != null &&
        walletConnection.provider != undefined &&
        walletConnection.provider != ""
          ? walletConnection.provider
          : window.ethereum
      );
      var polygonContract = new web3call.eth.Contract(
        polyfundcontractabi,
        config.polyfundcontract
      );
      const userss = await polygonContract.methods.users(addr).call();
      setrootInfo({
        id: userss.id
      });
    } else {
      setrootInfo({
        id: userdetails?.userid
      });
    }
  }

  async function getdetails() {
    if (
      walletConnection &&
      walletConnection.address &&
      walletConnection.address != undefined &&
      walletConnection.address != null &&
      walletConnection.address != ""
    ) {
      if (
        walletConnection.userdetails &&
        !walletConnection?.userdetails.isExist
      ) {
        window.location.href = "/join-now";
      } else {
        getuserdetails(walletConnection.address);
        getdashboarddetails(walletConnection.address);
        // getdashboarddetailsNewContr(walletConnection.address);
        // checkROI(walletConnection.address);
      }
    }
  }

  useEffect(() => {
    checkROI(walletConnection.address);
  }, [walletConnection.address, userdetails]);

  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem("wagmi.store")).state.data.account == null
    ) {
      window.location.href = "/";
    }
  }, []);
  async function checkROI() {
    let web3call = new Web3(
      walletConnection &&
      walletConnection.provider &&
      walletConnection.provider != null &&
      walletConnection.provider != undefined &&
      walletConnection.provider != ""
        ? walletConnection.provider
        : window.ethereum
    );
    let address = walletConnection.address;
    console.log(address, "1111111111111");
    if (address && address != "") {
      var polygonContract = new web3call.eth.Contract(
        polyfundcontractabi,
        config.polyfundcontract
      );
      console.log(config.polyfundcontract, "1111111111111A");
      // var polygonContractOld = new web3call.eth.Contract(
      //   polyfundcontractabiOld,
      //   config.polyfundcontractOld
      // );

      let roiBal;
      console.log(userdetails.RoiFrom, "userdetails.RoiFromNewSaran");
      // if (userdetails.RoiFrom > 0) {
      roiBal = await polygonContract.methods.checkRoi(address).call();
      console.log(roiBal, "roiBalroiBalroiBal1");
      // } else {
      //   roiBal = await polygonContractOld.methods.checkRoi(address).call();
      //   console.log(roiBal, "roiBalroiBalroiBal12");
      // }
      console.log(roiBal, "roiBalroiBalroiBal");
      let joinamt = await polygonContract.methods.joinAmount().call();

      setRoiBal(roiBal / config.decimals);
      setJoinAmt(joinamt / config.decimals);
    }
  }
  async function getdashboarddetails() {
    try {
      setprogress(true);
      let web3call = new Web3(
        walletConnection &&
        walletConnection.provider &&
        walletConnection.provider != null &&
        walletConnection.provider != undefined &&
        walletConnection.provider != ""
          ? walletConnection.provider
          : window.ethereum
      );

      let address = walletConnection.address;

      const multicall = new Multicall({
        web3Instance: web3call
      });

      var contractCallContext = [
        {
          reference: "details",
          contractAddress: config.polyfundcontract,
          abi: polyfundcontractabi,
          calls: [
            {
              reference: "userprofile",
              methodName: "users",
              methodParameters: [address]
            },
            {
              reference: "viewallDirectUserReferral",
              methodName: "viewallDirectUserReferral",
              methodParameters: [address]
            },
            {
              reference: "viewUserReferral",
              methodName: "viewUserReferral",
              methodParameters: [address]
            },
            {
              reference: "viewallgenerationIncome",
              methodName: "viewallgenerationIncome",
              methodParameters: [address]
            }
          ]
        }
      ];

      const results = await multicall.call(contractCallContext);
      var userprofile = await getFormatMulticall(results, "userprofile", 0);

      var viewallgenerationIncome = await getFormatMulticall(
        results,
        "viewallgenerationIncome",
        0
      );
      var myreferral = await getFormatMulticall(
        results,
        "viewallDirectUserReferral",
        0
      );

      if (userprofile[0]) {
        let userexist = true;
        let userid = await bignumber(userprofile[1]);
        let directrefferalCount = await myreferral.length;
        let DirectReferralIncome = await bignumber(userprofile[3]);
        let Generationincome1 = await bignumber(viewallgenerationIncome[0]);
        let Generationincome2 = await bignumber(viewallgenerationIncome[1]);
        let Generationincome3 = await bignumber(viewallgenerationIncome[2]);
        let Generationincome4 = await bignumber(viewallgenerationIncome[3]);
        let TotalGenerationIncome = await bignumber(userprofile[4]);
        let ReJoinCount = await bignumber(userprofile[10]);
        let RoiFrom = await bignumber(userprofile[8]);
        let RoiTo = await bignumber(userprofile[9]);
        let RejoinTopupAmount = await bignumber(userprofile[6]);
        let EarnedAmount = await bignumber(userprofile[5]);
        let withdrawAmt = await bignumber(userprofile[11]);
        let missedIncome = await bignumber(userprofile[12]);

        let data_user = {
          userexist: userexist,
          userid: userid,
          directrefferalCount: directrefferalCount,
          DirectReferralIncome: DirectReferralIncome,
          Generationincome1: Generationincome1,
          Generationincome2: Generationincome2,
          Generationincome3: Generationincome3,
          Generationincome4: Generationincome4,
          TotalGenerationIncome: TotalGenerationIncome,
          ReJoinCount: ReJoinCount,
          RejoinTopupAmount: RejoinTopupAmount,
          EarnedAmount: EarnedAmount,
          RoiTo: RoiTo,
          RoiFrom: RoiFrom,
          withdrawAmt: withdrawAmt,
          missedIncome: missedIncome
        };

        console.log(data_user, "data_user");
        setuserdetails(data_user);
      }
      setprogress(false);
    } catch (err) {
      setprogress(false);
      console.log(err, "err1");
    }
  }

  // async function getdashboarddetailsNewContr() {
  //   try {
  //     setprogress(true);
  //     let web3call = new Web3(
  //       walletConnection &&
  //       walletConnection.provider &&
  //       walletConnection.provider != null &&
  //       walletConnection.provider != undefined &&
  //       walletConnection.provider != ""
  //         ? walletConnection.provider
  //         : window.ethereum
  //     );

  //     let address = walletConnection.address;

  //     const multicall = new Multicall({
  //       web3Instance: web3call
  //     });

  //     var contractCallContext = [
  //       {
  //         reference: "details",
  //         contractAddress: config.polyfundcontractOld,
  //         abi: polyfundcontractabiOld,
  //         calls: [
  //           {
  //             reference: "userprofile",
  //             methodName: "users",
  //             methodParameters: [address]
  //           },
  //           {
  //             reference: "viewallDirectUserReferral",
  //             methodName: "viewallDirectUserReferral",
  //             methodParameters: [address]
  //           },
  //           {
  //             reference: "viewUserReferral",
  //             methodName: "viewUserReferral",
  //             methodParameters: [address]
  //           },
  //           {
  //             reference: "viewallgenerationIncome",
  //             methodName: "viewallgenerationIncome",
  //             methodParameters: [address]
  //           }
  //         ]
  //       }
  //     ];

  //     const results = await multicall.call(contractCallContext);
  //     var userprofile = await getFormatMulticall(results, "userprofile", 0);

  //     var viewallgenerationIncome = await getFormatMulticall(
  //       results,
  //       "viewallgenerationIncome",
  //       0
  //     );
  //     var myreferral = await getFormatMulticall(
  //       results,
  //       "viewallDirectUserReferral",
  //       0
  //     );

  //     if (userprofile[0]) {
  //       let userexist = true;
  //       let userid = await bignumber(userprofile[1]);
  //       let directrefferalCount = await myreferral.length;
  //       let DirectReferralIncome = await bignumber(userprofile[3]);
  //       let Generationincome1 = await bignumber(viewallgenerationIncome[0]);
  //       let Generationincome2 = await bignumber(viewallgenerationIncome[1]);
  //       let Generationincome3 = await bignumber(viewallgenerationIncome[2]);
  //       let Generationincome4 = await bignumber(viewallgenerationIncome[3]);
  //       let TotalGenerationIncome = await bignumber(userprofile[4]);
  //       let ReJoinCount = await bignumber(userprofile[10]);
  //       let RoiFrom = await bignumber(userprofile[8]);
  //       let RoiTo = await bignumber(userprofile[9]);
  //       let RejoinTopupAmount = await bignumber(userprofile[6]);
  //       let EarnedAmount = await bignumber(userprofile[5]);
  //       let withdrawAmt = await bignumber(userprofile[11]);
  //       let missedIncome = await bignumber(userprofile[12]);

  //       let data_user = {
  //         userexist: userexist,
  //         userid: userid,
  //         directrefferalCount: directrefferalCount,
  //         DirectReferralIncome: DirectReferralIncome,
  //         Generationincome1: Generationincome1,
  //         Generationincome2: Generationincome2,
  //         Generationincome3: Generationincome3,
  //         Generationincome4: Generationincome4,
  //         TotalGenerationIncome: TotalGenerationIncome,
  //         ReJoinCount: ReJoinCount,
  //         RejoinTopupAmount: RejoinTopupAmount,
  //         EarnedAmount: EarnedAmount,
  //         RoiTo: RoiTo,
  //         RoiFrom: RoiFrom,
  //         withdrawAmt: withdrawAmt,
  //         missedIncome: missedIncome
  //       };

  //       console.log(data_user, "data_user");
  //       setuserdetailsOld(data_user);
  //     }
  //     setprogress(false);
  //   } catch (err) {
  //     setprogress(false);
  //     console.log(err, "err2");
  //   }
  // }

  async function getrecentactivity() {
    try {
      //https://api.bscscan.com/api?module=account&action=txlist&address=${config.polyfundcontract}&sort=desc&apikey=FH7AQA2ES1JFBF979QCZRSU9AX87ZM5MRN
      //https://api-testnet.bscscan.com/api?module=account&action=txlist&address=${config.polyfundcontract}&sort=desc&apikey=FH7AQA2ES1JFBF979QCZRSU9AX87ZM5MRN

      let respData = await axios({
        method: "get",
        url: `${config.apiData}`
      });
      setDepositHisLoad(true);
      console.log(respData.data.result, "respDatarmuviespData");
      const web3_bsc = new Web3(config.dataUrl); //local
      var polygonContract = new web3_bsc.eth.Contract(
        polyfundcontractabi,
        config.polyfundcontract
      );
      let arrrec = [];
      if (
        respData.data &&
        respData.data.result &&
        respData.data.result.length > 0
      ) {
        syncEach(
          respData.data.result,
          async function (items, next) {
            let trans_hash = await web3_bsc.eth.getTransaction(items.hash);
            if (trans_hash && trans_hash.input) {
              let trans_detail = abiDecoder.decodeMethod(trans_hash.input);

              let userdet = await polygonContract.methods
                .users(items.from)
                .call();

              let date = moment.unix(items.timeStamp).toDate();

              var now = moment(new Date()); //todays date
              var end = moment(date); // another date

              var duration = now.diff(end, "minutes");
              var duration1 = now.diff(end, "hours");

              if (
                trans_detail &&
                trans_detail.name &&
                (trans_detail.name == "regUser" ||
                  trans_detail.name == "retopup")
              ) {
                let datas = {
                  address: web3_bsc.utils.toChecksumAddress(items.from),
                  userid: userdet.id,
                  event: trans_detail.name,
                  value:
                    trans_detail.params?.filter(
                      (el) => el.name == "_amount"
                    )?.[0]?.value / 1e18,
                  trx: items.hash,
                  min: duration,
                  hours: duration1,
                  date: items.timeStamp
                };
                arrrec.push(datas);
                next();
              } else {
                next();
              }
            } else {
              next();
            }
          },
          function (err, transformedItems) {
            console.log(arrrec, "arrrec");
            const filteredPeople = arrrec.filter(
              (item) => parseInt(item.userid) == userdetails?.userid
            );
            console.log(filteredPeople, "filteredPeople");
            setlatesttrx(filteredPeople);
            setDepositHisLoad(false);
          }
        );
      }
    } catch (err) {
      console.log(err, "=======errrrrrrrrrrrr=======");
    }
  }

  async function getuserdetails(addr) {}

  async function handleClick(id) {
    try {
      if (id != 0) {
        var selectId = id;
        if (
          selectId &&
          selectId != null &&
          selectId != undefined &&
          selectId != ""
        ) {
          setsearchid("");
          let userid = selectId;
          settreeprogress(true);
          setistree(true);
          let web3 = new Web3(
            walletConnection &&
            walletConnection.provider &&
            walletConnection.provider != null &&
            walletConnection.provider != undefined &&
            walletConnection.provider != ""
              ? walletConnection.provider
              : window.ethereum
          );
          var polygonContract = new web3.eth.Contract(
            polyfundcontractabi,
            config.polyfundcontract
          );
          let c_address = await polygonContract.methods.userList(userid).call();

          if (
            c_address &&
            c_address != null &&
            c_address != undefined &&
            c_address != "" &&
            c_address != "0x0000000000000000000000000000000000000000"
          ) {
            getuserdetails(c_address);
            loadTree(c_address);
            getCurrId(c_address);
          } else {
            settreeprogress(false);
            setistree(false);
            toast.error("Enter Valid Id1", toasterOption);
          }
        } else {
          settreeprogress(false);
          toast.error("Enter Valid Id2", toasterOption);
        }
      } else {
      }
    } catch (err) {
      console.log(err);
      settreeprogress(false);
      toast.error("Something wrong", toasterOption);
    }
  }

  async function searchbyid() {
    try {
      if (
        searchid &&
        searchid != null &&
        searchid != undefined &&
        searchid != ""
      ) {
        let userid = searchid;
        settreeprogress(true);
        setistree(true);
        let web3 = new Web3(
          walletConnection &&
          walletConnection.provider &&
          walletConnection.provider != null &&
          walletConnection.provider != undefined &&
          walletConnection.provider != ""
            ? walletConnection.provider
            : window.ethereum
        );
        var polygonContract = new web3.eth.Contract(
          polyfundcontractabi,
          config.polyfundcontract
        );
        let c_address = await polygonContract.methods.userList(userid).call();

        if (
          c_address &&
          c_address != null &&
          c_address != undefined &&
          c_address != "" &&
          c_address != "0x0000000000000000000000000000000000000000"
        ) {
          getuserdetails(c_address);
          loadTree(c_address);
          getCurrId(c_address);
        } else {
          settreeprogress(false);
          setistree(false);
          toast.error("Enter Valid Id", toasterOption);
        }
      } else {
        settreeprogress(false);
        setistree(false);
        toast.error("Enter Valid Id", toasterOption);
      }
    } catch (err) {
      settreeprogress(false);
      setistree(false);
      toast.error("Enter Valid Id", toasterOption);
    }
  }

  function getFormatMulticall(results, name, pos) {
    try {
      var index =
        results &&
        results.results &&
        results.results.details &&
        results.results.details.callsReturnContext &&
        results.results.details.callsReturnContext.findIndex(
          (val) => val.reference == name
        );
      var returnVal =
        results.results.details.callsReturnContext[index] &&
        results.results.details.callsReturnContext[index].returnValues
          ? results.results.details.callsReturnContext[index].returnValues
          : "";
      return returnVal;
    } catch (err) {
      console.log(err, "==+++++++==");
      return "";
    }
  }

  async function bignumber(inputs) {
    let ten = new BigNumber(inputs.hex, 16);
    var value = ten.toString(10);
    return value;
  }

  async function copyToClipboard() {
    if (walletConnection.address) {
      // var text = config.frontend + "/join-now/" + walletConnection.address;
      // navigator.clipboard.writeText(text);
      copy(config.frontend + "/join-now/" + walletConnection.address);
      toast.success("Copied Successfully", toasterOption);
    } else {
      toast.error("Please connect your wallet", toasterOption);
    }
  }
  async function empty() {}

  const renderer = ({
    days,
    Month,
    Year,
    hours,
    minutes,
    seconds,
    completed
  }) => {
    const formattedDays = isNaN(days) ? "00" : days;
    const formattedHours = isNaN(hours) ? "00" : hours;
    const formattedMinutes = isNaN(minutes) ? "00" : minutes;
    const formattedSeconds = isNaN(seconds) ? "00" : seconds;
    if (completed) {
      return <span>10 days completed now you can able to rejoin</span>;
    } else {
      return (
        <span>
          {formattedDays}d {formattedHours}h {formattedMinutes}m{" "}
          {formattedSeconds}s Left
        </span>
      );
    }
  };

  function convert(n) {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n
      .toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    return +pow < 0
      ? sign +
          "0." +
          "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
          lead +
          decimal
      : sign +
          lead +
          (+pow >= decimal.length
            ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
            : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
  }

  const ClaimRoiDirect = async () => {
    setprogress(true);
    if (
      walletConnection &&
      walletConnection.address &&
      walletConnection.address != undefined &&
      walletConnection.address != null &&
      walletConnection.address != ""
    ) {
      if (
        walletConnection.userdetails &&
        walletConnection.userdetails.isExist
      ) {
        let web3 = new Web3(
          walletConnection &&
          walletConnection.provider &&
          walletConnection.provider != null &&
          walletConnection.provider != undefined &&
          walletConnection.provider != ""
            ? walletConnection.provider
            : window.ethereum
        );

        // let CheckZero =
        //   userdetails?.RoiFrom > 0
        //     ? userdetails.EarnedAmount / config.decimals + roiBal
        //     : userdetailsOld.EarnedAmount / config.decimals + roiBal;

        // if (userdetails.EarnedAmount / config.decimals + roiBal == 0) {
        //   setprogress(false);
        //   toast.error("Amount must be greater than zero", toasterOption);
        //   return false;
        // }

        var polygonContract = new web3.eth.Contract(
          polyfundcontractabi,
          config.polyfundcontract
        );

        // var polygonContractOld = new web3.eth.Contract(
        //   polyfundcontractabiOld,
        //   config.polyfundcontractOld
        // );

        // if (userdetails.RoiFrom > 0) {

        const timeData = await polygonContract.methods
          .checkROIClaimenable(walletConnection.address)
          .call();

        const usdtContract = new web3.eth.Contract(
          usdtcontractABI,
          config.USDTContractAddress
        );
        console.log(timeData, "timeDatatimeData");

        if (!timeData) {
          toast.error(
            "You are not able to claim now, after 10 days you can claim"
          );
          setprogress(false);
          return false;
        }

        let RemainAmt = await polygonContract.methods
          .retopUpremainingAmount(walletConnection.address)
          .call();
        RemainAmt = web3.utils.fromWei(RemainAmt);

        let usdtBal = await usdtContract.methods
          .balanceOf(walletConnection.address)
          .call();
        usdtBal = web3.utils.fromWei(usdtBal);

        console.log(
          RemainAmt,
          usdtBal,
          RemainAmt > usdtBal,
          "Checkkkkefrewfcef"
        );

        if (RemainAmt <= 0) {
          await polygonContract.methods
            .cliamRoiDirectReward()
            .send({ from: walletConnection.address })
            .then(async (result) => {
              if (result.transactionHash) {
                toast.success("Claim & Rejoin successfully completed", toasterOption);
                setprogress(false);
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }
            })
            .catch((err) => {
              console.log(err);
              setprogress(false);
              toast.error("Something wrong in withdraw", toasterOption);
            });
        } else {
          if (parseFloat(RemainAmt) > parseFloat(usdtBal)) {
            toast.error(
              `Your USDT balance is low yout must have ${RemainAmt}`,
              toasterOption
            );
            setprogress(false);
            return false;
          } else {
            let estimateGas1 = await usdtContract.methods
              .approve(config.polyfundcontract, (RemainAmt * 1e18).toString())
              .estimateGas({
                from: walletConnection.address
              })
              .catch((err) => {
                console.log(err, "sdfvdsgd");
              });

            var BNBbalance = await web3.eth.getBalance(
              walletConnection.address
            );
            var gasPricebusd = await web3.eth.getGasPrice();

            if ((estimateGas1 * gasPricebusd) / 1e18 > BNBbalance / 1e18) {
              setprogress(false);
              toast.error("Your BNB balance is low", toasterOption);
              return false;
            }

            await usdtContract.methods
              .approve(config.polyfundcontract, (RemainAmt * 1e18).toString())
              .send({ from: walletConnection.address })
              .then(async (result) => {
                if (result && result.status && result.transactionHash) {
                  await polygonContract.methods
                    .cliamRoiDirectReward()
                    .send({ from: walletConnection.address })
                    .then(async (result) => {
                      if (result.transactionHash) {
                        toast.success(
                          "Claim & Rejoin successfully completed",
                          toasterOption
                        );
                        setprogress(false);
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                      setprogress(false);
                      toast.error("Something wrong in withdraw", toasterOption);
                    });
                }
              });
          }
        }
      }
    }
  };

  const claimGeneralIncome = async () => {
    setprogress1(true);
    if (
      walletConnection &&
      walletConnection.address &&
      walletConnection.address != undefined &&
      walletConnection.address != null &&
      walletConnection.address != ""
    ) {
      if (
        walletConnection.userdetails &&
        walletConnection.userdetails.isExist
      ) {
        let web3 = new Web3(
          walletConnection &&
          walletConnection.provider &&
          walletConnection.provider != null &&
          walletConnection.provider != undefined &&
          walletConnection.provider != ""
            ? walletConnection.provider
            : window.ethereum
        );

        // if (
        //   parseFloat(userdetailsOld?.TotalGenerationIncome) / config.decimals >
        //     0 &&
        //   userdetailsOld?.directrefferalCount >= 4
        // ) {
        //   var polygonContract = new web3.eth.Contract(
        //     polyfundcontractabiOld,
        //     config.polyfundcontractOld
        //   );
        //   await polygonContract.methods
        //     .cliamLevelReward()
        //     .send({ from: walletConnection.address })
        //     .then(async (result) => {
        //       if (result.transactionHash) {
        //         toast.success("Withdraw successfully completed", toasterOption);
        //         setprogress1(false);
        //         setTimeout(() => {
        //           window.location.reload();
        //         }, 1000);
        //       }
        //     })
        //     .catch((err) => {
        //       console.log(err);
        //       setprogress1(false);
        //       toast.error("Something wrong in withdraw", toasterOption);
        //     });
        // } else {
        if (
          userdetails?.directrefferalCount >= 4 &&
          parseFloat(userdetails?.TotalGenerationIncome) / config.decimals > 0
        ) {
          var polygonContract = new web3.eth.Contract(
            polyfundcontractabi,
            config.polyfundcontract
          );

          await polygonContract.methods
            .cliamLevelReward()
            .send({ from: walletConnection.address })
            .then(async (result) => {
              if (result.transactionHash) {
                toast.success("Withdraw successfully completed", toasterOption);
                setprogress1(false);
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }
            })
            .catch((err) => {
              console.log(err);
              setprogress1(false);
              toast.error("Something wrong in withdraw", toasterOption);
            });
        } else {
          setprogress1(false);
          toast.error(
            "You must be refer 3 user and balance must be greater than zero",
            toasterOption
          );
        }
        // }
      }
    }
  };

  const reJoinUser = async () => {
    try {
      // console.log(new Date(Number(userdetails.RoiTo) * 1000).getTime(),new Date().getTime(),"saranRaj")
      if (
        new Date(Number(userdetails.RoiTo) * 1000).getTime() >
        new Date().getTime()
      ) {
        toast.error(
          "You are active current plan, after 10 days you can rejoin"
        );
        return false;
      }
      let web3call = new Web3(
        walletConnection &&
        walletConnection.provider &&
        walletConnection.provider != null &&
        walletConnection.provider != undefined &&
        walletConnection.provider != ""
          ? walletConnection.provider
          : window.ethereum
      );

      var polygonContractOld = new web3call.eth.Contract(
        polyfundcontractabiOld,
        config.polyfundcontractOld
      );

      var roiBalT = await polygonContractOld.methods
        .checkRoi(walletConnection.address)
        .call();
      roiBalT = roiBalT / config.decimals;

      // if (parseInt(userdetailsOld.EarnedAmount) + parseInt(roiBalT) > 0) {
      //   toast.error("You must withdraw your daily rewards");
      //   return false;
      // }
      setprogress3(true);
      if (
        walletConnection &&
        walletConnection.address &&
        walletConnection.address != undefined &&
        walletConnection.address != null &&
        walletConnection.address != ""
      ) {
        let web3 = new Web3(
          walletConnection &&
          walletConnection.provider &&
          walletConnection.provider != null &&
          walletConnection.provider != undefined &&
          walletConnection.provider != ""
            ? walletConnection.provider
            : window.ethereum
        );

        var polygonContract = new web3.eth.Contract(
          polyfundcontractabi,
          config.polyfundcontract
        );

        var USDTcontract = new web3.eth.Contract(
          usdtcontractABI,
          config.USDTContractAddress
        );

        let Tokenbalnce = await USDTcontract.methods
          .balanceOf(walletConnection.address)
          .call();
        let joinamt = await polygonContract.methods.joinAmount().call();
        var amt = await convert(joinamt);

        if (Tokenbalnce / config.decimals > amt / config.decimals) {
          let estimateGas1 = await USDTcontract.methods
            .approve(config.polyfundcontract, amt.toString())
            .estimateGas({
              from: walletConnection.address
            })
            .catch((err) => {
              console.log(err, "sdfvdsgd");
            });

          var BNBbalance = await web3.eth.getBalance(walletConnection.address);
          var gasPricebusd = await web3.eth.getGasPrice();

          if ((estimateGas1 * gasPricebusd) / 1e18 > BNBbalance / 1e18) {
            setprogress(false);
            toast.error("Your BNB balance is low", toasterOption);
            return false;
          }

          await USDTcontract.methods
            .approve(config.polyfundcontract, amt.toString())
            .send({ from: walletConnection.address })
            .then(async (result) => {
              if (result && result.status && result.transactionHash) {
                let estimateGas = await polygonContract.methods
                  .retopup(amt.toString())
                  .estimateGas({
                    from: walletConnection.address
                  });

                let register = await polygonContract.methods
                  .retopup(amt.toString())
                  .send({
                    from: walletConnection.address
                  });
                if (register && register.status && register.transactionHash) {
                  setprogress3(false);
                  toast.success("Rejoin Successfully", toasterOption);
                  window.location.href = "/dashboard";
                } else {
                  setprogress3(false);
                  toast.error("Failed , try again later", toasterOption);
                }
              }
            })
            .catch((error) => {
              console.log(error, "error");
              toast.error("Approve failed", toasterOption);
              setprogress3(false);
            });
        } else {
          setprogress3(false);
          toast.error("USDT balance low", toasterOption);
        }
      } else {
        setprogress3(false);
        toast.error("Please connect BNB network", toasterOption);
      }
    } catch (err) {
      console.log(err, "errrrrrrmuvitha");
      setprogress3(false);
      toast.error("Failed , try again later", toasterOption);
    }
  };

  function timestampToIsoDate(timestamp) {
    const date = new Date(timestamp * 1000); // Multiply by 1000 to convert from seconds to milliseconds
    return date.toISOString();
  }

  const DateofNumber = () => {
    let result = 0;

    const currentDateMillis = new Date().getTime();
    var roiFromDateMillis =
      parseInt(userdetails.RoiTo) * 1000 - 10 * 24 * 60 * 60 * 1000;

    // if (parseInt(userdetails.RoiTo) === 0) {
    //   roiFromDateMillis =
    //     parseInt(userdetailsOld.RoiTo) * 1000 - 10 * 24 * 60 * 60 * 1000;
    // }
    const dayDifference = Math.floor(
      (currentDateMillis - roiFromDateMillis) / (24 * 60 * 60 * 1000)
    );
    console.log(dayDifference, "dayDifference");
    if (dayDifference >= 0 && dayDifference <= 10) {
      result = dayDifference;
    } else {
      result = 0;
    }
    console.log(result, "sedfcsdecf");
    return result;
  };

  function truncateAddress(address, startCharsToShow, endCharsToShow) {
    if (address.length <= startCharsToShow + endCharsToShow) {
      return address;
    }

    const truncated =
      address.substring(0, startCharsToShow) +
      "..." +
      address.substring(address.length - endCharsToShow);

    return truncated;
  }

  return (
    <div>
      <Navbar />
      <div className="dashboard">
        <div className="dashboard_shade">
          <div className="container">
            <div className="h_flx">
              <h3>Dashboard</h3>
              <div className="box">
                Affiliate link :{" "}
                <p className="d-flex align-items-center">
                  {/* { walletConnection?.address
                    ? walletConnection?.address
                    : "-----------------"} */}
                  {config.frontend +
                    "/join-now/" +
                    truncateAddress(walletConnection.address, 6, 4)}
                  <img
                    src={require("../assets/images/copy.png")}
                    className="img-fluid ms-2 mb-0 h-auto"
                    onClick={() => {
                      copyToClipboard();
                    }}
                  />
                </p>
              </div>
              <div className="box">
                {/* <Countdown
                  date={
                    userdetails?.RoiFrom === 0
                      ? new Date(parseInt(userdetailsOld?.RoiTo) * 1000)
                      : new Date(parseInt(userdetails?.RoiTo) * 1000)
                  }
                  autoStart={true}
                  onStart={() =>
                    userdetails?.RoiFrom === 0
                      ? new Date(
                          parseInt(userdetailsOld?.RoiTo) * 1000 -
                            10 * 24 * 60 * 60 * 1000
                        )
                      : new Date(
                          parseInt(userdetails?.RoiTo) * 1000 -
                            10 * 24 * 60 * 60 * 1000
                        )
                  }
                  renderer={renderer}
                /> */}
                <Countdown
                  date={new Date(parseInt(userdetails?.RoiTo) * 1000)}
                  autoStart={true}
                  onStart={() =>
                    new Date(
                      parseInt(userdetails?.RoiTo) * 1000 -
                        10 * 24 * 60 * 60 * 1000
                    )
                  }
                  renderer={renderer}
                />
              </div>
            </div>
            <div className="validity_box mb-4">
              <h5>Validity Period</h5>
              <div class="b_flx">
                <div className="w-100 me-lg-3">
                  <div class="progress">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      aria-valuenow="80"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{
                        width: `${((DateofNumber() * 11) / 110) * 100}%`
                      }}
                    ></div>
                  </div>
                  <div className="flx">
                    {console.log(DateofNumber(), "DateofNumber")}
                    <p>
                      Daily 11 USDT for 10 Days - 110 USDT ({DateofNumber()}/10)
                    </p>
                    <p className="ylw">{DateofNumber() * 11} USDT / 110 USDT</p>
                  </div>
                </div>
                <div>
                  {/* <>
                    {progress3 ? (
                      <button className="primary_btn">
                        <center>
                          <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          Processing...
                        </center>
                      </button>
                    ) : (
                      <button className="primary_btn" onClick={ClaimRoiDirect}>
                        {userdetails.EarnedAmount / config.decimals + roiBal ==
                        0
                          ? "Rejoin"
                          : "Claim ROI"}
                      </button>
                    )}
                  </> */}
                  {/* )} reJoinUser */}
                </div>
              </div>
            </div>
            <div className="bg_box mb-4">
              <div className="in_box">
                <div className="row">
                  <div className="col-lg-3">
                    <div className="box">
                      <div className="img_box">
                        <img
                          src={require("../assets/images/myid.png")}
                          className="img-fluid"
                        />
                      </div>
                      <div>
                        <h5>{userdetails?.userid ? userdetails.userid : 0}</h5>
                        <p>My ID</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="box">
                      <div className="img_box">
                        <img
                          src={require("../assets/images/refferal.png")}
                          className="img-fluid"
                        />
                      </div>
                      <div>
                        <h5>
                          {userdetails?.directrefferalCount
                            ? userdetails?.directrefferalCount
                            : 0}
                        </h5>
                        <p> Direct Referral </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="box">
                      <div className="img_box">
                        <img
                          src={require("../assets/images/income.png")}
                          className="img-fluid"
                        />
                      </div>
                      <div>
                        <div className="d-flex align-items-end">
                          <h5>
                            {userdetails?.DirectReferralIncome
                              ? userdetails?.DirectReferralIncome /
                                config.decimals
                              : 0}
                          </h5>
                          <span className="mb-2"> (USDT)</span>
                        </div>
                        <p> Direct Referral Income </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="box">
                      <div className="img_box">
                        <img
                          src={require("../assets/images/usdt.png")}
                          className="img-fluid"
                        />
                      </div>
                      <div>
                        <div class="d-flex align-items-end">
                          <h5>
                            {
                              // userdetails?.RoiFrom > 0 ? (
                              <>
                                {" "}
                                {userdetails?.withdrawAmt
                                  ? (userdetails?.withdrawAmt / 1e18).toFixed(3)
                                  : 0}
                              </>
                              // ) : userdetails?.RoiFrom == 0 ? (
                              // <>
                              //   {userdetails?.withdrawAmt
                              //     ? (
                              //         userdetails?.withdrawAmt / 1e18 +
                              //         userdetailsOld?.withdrawAmt / 1e18
                              //       ).toFixed(3)
                              //     : 0}
                              // </>
                              // ) :
                              // (
                              //   ""
                              // )
                            }
                          </h5>
                          <span className="mb-2"> (USDT)</span>
                        </div>
                        <p> Total USDT claimed </p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-3">
                    <div className="box">
                      <div className="img_box">
                        <img
                          src={require("../assets/images/myteam.png")}
                          className="img-fluid"
                        />
                      </div>
                      <div>
                        <h5> 23</h5>
                        <p>My Team</p>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="dash_box mb-4">
              <div className="row">
                <div className="col-lg-3">
                  <div className="box">
                    <div className="bdr">
                      <p>Generation income</p>
                      <h6>level 1</h6>
                    </div>
                    <div>
                      <h5>
                        {userdetails?.Generationincome1
                          ? parseFloat(userdetails?.Generationincome1) /
                            config.decimals
                          : 0}
                      </h5>
                      <span>(USDT)</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="box">
                    <div className="bdr">
                      <p>Generation income</p>
                      <h6>level 2</h6>
                    </div>
                    <div>
                      <h5>
                        {userdetails?.Generationincome2
                          ? parseFloat(userdetails?.Generationincome2) /
                            config.decimals
                          : 0}
                      </h5>
                      <span>(USDT)</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="box">
                    <div className="bdr">
                      <p>Generation income</p>
                      <h6>level 3</h6>
                    </div>
                    <div>
                      <h5>
                        {userdetails?.Generationincome3
                          ? parseFloat(userdetails?.Generationincome3) /
                            config.decimals
                          : 0}
                      </h5>
                      <span>(USDT)</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="box">
                    <div className="bdr">
                      <p>Generation income</p>
                      <h6>level 4 </h6>
                    </div>
                    <div>
                      <h5>
                        {userdetails?.Generationincome4
                          ? parseFloat(userdetails?.Generationincome4) /
                            config.decimals
                          : 0}
                      </h5>
                      <span>(USDT)</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg_box mb-4">
              <div className="in_box  in_boxchg ">
                <div className="row">
                  <div className="col-lg-3">
                    <div className="box">
                      <div className="img_box">
                        <img
                          src={require("../assets/images/tot.png")}
                          className="img-fluid"
                        />
                      </div>
                      <div>
                        <div className="d-flex align-items-end">
                          <h5>
                            {userdetails?.Generationincome1
                              ? parseFloat(userdetails?.Generationincome1) /
                                  config.decimals +
                                parseFloat(userdetails?.Generationincome2) /
                                  config.decimals +
                                parseFloat(userdetails?.Generationincome3) /
                                  config.decimals +
                                parseFloat(userdetails?.Generationincome4) /
                                  config.decimals
                              : 0}
                          </h5>
                          <span className="mb-2">(USDT)</span>
                        </div>
                        <p> Total Generation Income </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="box justify-content-lg-center my-3 my-lg-0">
                      <div className="img_box">
                        <img
                          src={require("../assets/images/rejoin.png")}
                          className="img-fluid"
                        />
                      </div>
                      <div>
                        <h5>
                          {userdetails?.ReJoinCount
                            ? userdetails?.ReJoinCount
                            : 0}
                        </h5>
                        <p> Re-Join Count </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="box">
                      <div className="img_box">
                        <img
                          src={require("../assets/images/rejoin_topup.png")}
                          className="img-fluid"
                        />
                      </div>
                      <div>
                        <div className="d-flex align-items-end">
                          <h5>
                            {/* {userdetails.RejoinTopupAmount
                              ? (
                                  parseFloat(userdetails.RejoinTopupAmount) /
                                  config.decimals
                                ).toFixed(4)
                              : 0} */}
                            {userdetails?.ReJoinCount
                              ? userdetails?.ReJoinCount * joinAmt
                              : 0}
                          </h5>
                          <span className="mb-2">(USDT)</span>
                        </div>
                        <p> Rejoin Topup Amount </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="box">
                      <div className="img_box">
                        <img
                          src={require("../assets/images/rejoin.png")}
                          className="img-fluid"
                        />
                      </div>
                      <div>
                        <div className="d-flex align-items-end">
                          <h5>
                            {
                              // userdetails?.RoiFrom == 0 ? (
                              //   <>
                              //     {new Date(
                              //       Number(userdetailsOld.RoiTo) * 1000
                              //     ).getTime() > new Date().getTime()
                              //       ? "Active"
                              //       : "Inactive"}
                              //   </>
                              // ) : (
                              <>
                                {new Date(
                                  Number(userdetails.RoiTo) * 1000
                                ).getTime() > new Date().getTime()
                                  ? "Active"
                                  : "Inactive"}
                              </>
                              // )
                            }
                          </h5>
                        </div>
                        <p> User Status </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="dash_box mb-4 income_boxes">
              <div className="row">
                <div className="col-lg-6 d-flex mb-3 mb-lg-0">
                  <div className="box flex-row justify-content-between">
                    <div>
                      <div class="d-flex align-items-end">
                        <h5>
                          {
                            // userdetails?.RoiFrom > 0 ? (
                            <>
                              {" "}
                              {userdetails.EarnedAmount
                                ? (
                                    userdetails.EarnedAmount / config.decimals +
                                    roiBal
                                  ).toFixed(4)
                                : 0}
                            </>
                            // ) : userdetails?.RoiFrom == 0 ? (
                            //   <>
                            //     {" "}
                            //     {userdetails.EarnedAmount
                            //       ? (
                            //           userdetailsOld.EarnedAmount /
                            //             config.decimals +
                            //           roiBal
                            //         ).toFixed(4)
                            //       : 0}
                            //   </>
                            // ) : (
                            //   ""
                            // )
                          }{" "}
                        </h5>
                        <span className="mb-3"> (USDT)</span>
                      </div>
                      <p style={{ textAlign: "left" }}>
                        {/* {userdetails.EarnedAmount / config.decimals + roiBal ==
                        0
                          ? "Rejoin"
                          : "Daily Rewards"}{" "} */}
                        Daily Rewards
                      </p>
                      {/* + Direct Referral Income */}
                    </div>
                    {progress ? (
                      <button className="primary_btn">
                        <center>
                          <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          Processing...
                        </center>
                      </button>
                    ) : (
                      <button
                        className="primary_btn"
                        // disabled={
                        //   userdetails.EarnedAmount / config.decimals + roiBal ==
                        //   0
                        //     ? true
                        //     : false
                        // }
                        onClick={ClaimRoiDirect}
                      >
                        Claim & Rejoin
                      </button>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 d-flex">
                  <div className="box flex-row justify-content-between">
                    <div>
                      <div class="d-flex align-items-end">
                        <h5>
                          {" "}
                          {
                            // parseFloat(userdetailsOld?.TotalGenerationIncome) /
                            //   config.decimals >
                            //   0 && userdetailsOld?.directrefferalCount >= 4
                            //   ? parseFloat(userdetails?.TotalGenerationIncome) /
                            //       config.decimals +
                            //     parseFloat(
                            //       userdetailsOld?.TotalGenerationIncome
                            //     ) /
                            //       config.decimals
                            //   :
                            parseFloat(userdetails?.TotalGenerationIncome) /
                              config.decimals
                          }{" "}
                        </h5>
                        <span className="mb-3">(USDT)</span>
                      </div>
                      <p>Available Generation Income </p>
                    </div>

                    <div className="text-end">
                      {progress1 ? (
                        <button className="primary_btn">
                          <center>
                            <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            Processing...
                          </center>
                        </button>
                      ) : (
                        <button
                          className="primary_btn mb-2"
                          onClick={claimGeneralIncome}
                          // disabled={
                          //   parseFloat(userdetails?.TotalGenerationIncome) /
                          //     config.decimals ==
                          //   0
                          //     ? true
                          //     : false
                          // }
                        >
                          Withdraw
                        </button>
                      )}

                      <span className="d-block">
                        (Need 4 direct referrals to withdraw)
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg_box tree mb-4">
              <div className="t_flx">
                <h3>Genealogy Tree</h3>

                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search ID"
                    value={searchid}
                    disabled={walletConnection.address == "" ? "disabled" : ""}
                    onChange={(e) => setsearchid(e.target.value)}
                    // onKeyPress={() => {
                    //   if (walletConnection?.address != "") {
                    //     searchbyid();
                    //   } else {
                    //     empty();
                    //   }
                    // }}
                  />
                  <button
                    // class="input-group-text"
                    className="primary_btn ms-2 d-flex align-items-center"
                    style={{ cursor: "pointer", padding: "10px 20px" }}
                    onClick={() => {
                      if (walletConnection?.address != "") {
                        searchbyid();
                      } else {
                        empty();
                      }
                    }}
                  >
                    {/* <img
                      src={require("../assets/images/search.png")}
                      className="img-fluid"
                    /> */}
                    Search
                  </button>
                </div>
              </div>
              {istree ? (
                <svg
                  class="pl"
                  viewBox="0 0 128 128"
                  width="128px"
                  height="128px"
                >
                  <defs>
                    <linearGradient id="pl-grad" x1="0" y1="0" x2="1" y2="1">
                      <stop offset="0%" stop-color="#000" />
                      <stop offset="100%" stop-color="#fff" />
                    </linearGradient>
                    <mask id="pl-mask">
                      <rect
                        x="0"
                        y="0"
                        width="128"
                        height="128"
                        fill="url(#pl-grad)"
                      />
                    </mask>
                  </defs>
                  <g
                    stroke-linecap="round"
                    stroke-width="8"
                    stroke-dasharray="32 32"
                  >
                    <g stroke="hsl(193,90%,50%)">
                      <line class="pl__line1" x1="4" y1="48" x2="4" y2="80" />
                      <line class="pl__line2" x1="19" y1="48" x2="19" y2="80" />
                      <line class="pl__line3" x1="34" y1="48" x2="34" y2="80" />
                      <line class="pl__line4" x1="49" y1="48" x2="49" y2="80" />
                      <line class="pl__line5" x1="64" y1="48" x2="64" y2="80" />
                      <g transform="rotate(180,79,64)">
                        <line
                          class="pl__line6"
                          x1="79"
                          y1="48"
                          x2="79"
                          y2="80"
                        />
                      </g>
                      <g transform="rotate(180,94,64)">
                        <line
                          class="pl__line7"
                          x1="94"
                          y1="48"
                          x2="94"
                          y2="80"
                        />
                      </g>
                      <g transform="rotate(180,109,64)">
                        <line
                          class="pl__line8"
                          x1="109"
                          y1="48"
                          x2="109"
                          y2="80"
                        />
                      </g>
                      <g transform="rotate(180,124,64)">
                        <line
                          class="pl__line9"
                          x1="124"
                          y1="48"
                          x2="124"
                          y2="80"
                        />
                      </g>
                    </g>
                    <g stroke="hsl(283,90%,50%)" mask="url(#pl-mask)">
                      <line class="pl__line1" x1="4" y1="48" x2="4" y2="80" />
                      <line class="pl__line2" x1="19" y1="48" x2="19" y2="80" />
                      <line class="pl__line3" x1="34" y1="48" x2="34" y2="80" />
                      <line class="pl__line4" x1="49" y1="48" x2="49" y2="80" />
                      <line class="pl__line5" x1="64" y1="48" x2="64" y2="80" />
                      <g transform="rotate(180,79,64)">
                        <line
                          class="pl__line6"
                          x1="79"
                          y1="48"
                          x2="79"
                          y2="80"
                        />
                      </g>
                      <g transform="rotate(180,94,64)">
                        <line
                          class="pl__line7"
                          x1="94"
                          y1="48"
                          x2="94"
                          y2="80"
                        />
                      </g>
                      <g transform="rotate(180,109,64)">
                        <line
                          class="pl__line8"
                          x1="109"
                          y1="48"
                          x2="109"
                          y2="80"
                        />
                      </g>
                      <g transform="rotate(180,124,64)">
                        <line
                          class="pl__line9"
                          x1="124"
                          y1="48"
                          x2="124"
                          y2="80"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              ) : (
                <Scrollbars style={{ width: "100%", height: 580 }}>
                  <div className="plan_pool">
                    <div className="plan_pool_head">
                      <img
                        src={require("../assets/images/user_1.png")}
                        className="img-fluid"
                        alt="Icon"
                        data-tooltip-id="my-tooltip-1"
                      />
                    </div>
                    <ReactTooltip
                      id="my-tooltip-1"
                      place="bottom"
                      content={
                        <p className="tooltip_data text-center myIdde">
                          ID {rootInfo.id}
                          {/* {userdetails.userid} */}
                        </p>
                      }
                    />

                    {/* First Row */}
                    <div className="plan_pool_first_row">
                      {firstRow &&
                        firstRow.length > 0 &&
                        firstRow.map((item1, index1) => {
                          var logo = getTreeImage(index1, item1);
                          var index = userdetail1.findIndex(
                            (val) =>
                              val.address &&
                              val.address.toLowerCase() === item1.toLowerCase()
                          );
                          var userid = 0;
                          var level = 0;
                          var toROI = 0;
                          if (index !== -1) {
                            userid = userdetail1[index].id;
                            level = userdetail1[index].currentLevel;
                            toROI = userdetail1[index].roiTo;
                          }
                          return (
                            <div
                              id={item1}
                              className="plan_pool_head_child"
                              data-tip
                              data-for={item1}
                            >
                              {/* new Date(
Number(userdetails.RoiTo) * 1000
).getTime() > new Date().getTime() */}
                              {/* {logo && logo !== "" && ( */}
                              {userid == 0 ? (
                                <img
                                  src={require(`../assets/images/user_deac.png`)}
                                  id={"user_two" + item1 + userid}
                                  onClick={() => handleClick(userid)}
                                  className={"img-fluid"}
                                  alt="Icon"
                                />
                              ) : (
                                <img
                                  src={require(`../assets/images/${
                                    new Date(Number(toROI) * 1000).getTime() >
                                    new Date().getTime()
                                      ? logo
                                      : "user_red_inac.png"
                                  }`)}
                                  id={"user_two" + item1 + userid}
                                  onClick={() => handleClick(userid)}
                                  className={
                                    new Date(Number(toROI) * 1000).getTime() >
                                    new Date().getTime()
                                      ? "img-fluid"
                                      : "img-fluid isuser"
                                  }
                                  alt="Icon"
                                />
                              )}

                              {/* )} */}
                              <ReactTooltip
                                anchorId={"user_two" + item1 + userid}
                                place="bottom"
                                content={
                                  <p className="tooltip_data text-center myIdde">
                                    ID {userid}
                                  </p>
                                }
                              />
                            </div>
                          );
                        })}
                    </div>

                    {/* Second Row */}
                    <div className="plan_pool_second_row">
                      {secondRow &&
                        secondRow.length > 0 &&
                        secondRow.map((item2, index2) => {
                          return (
                            <div>
                              {item2 &&
                                item2.length > 0 &&
                                item2.map((subitem1, subindex1) => {
                                  var logo = getTreeImage(index2, subitem1);
                                  var index = userdetail2.findIndex(
                                    (val) =>
                                      val.address &&
                                      val.address.toLowerCase() ===
                                        subitem1.toLowerCase()
                                  );
                                  var userid = 0;
                                  var level = 0;
                                  var toROI = 0;
                                  if (index !== -1) {
                                    userid = userdetail2[index].id;
                                    level = userdetail2[index].currentLevel;
                                    toROI = userdetail2[index].roiTo;
                                  }
                                  return (
                                    <div
                                      id={subitem1}
                                      className="plan_pool_head_child"
                                      data-tip
                                      data-for={subitem1}
                                    >
                                      {userid == 0 ? (
                                        <img
                                          src={require(`../assets/images/user_deac.png`)}
                                          id={
                                            "user_three" +
                                            index2 +
                                            subindex1 +
                                            userid
                                          }
                                          onClick={() => handleClick(userid)}
                                          className={"img-fluid"}
                                          alt="Icon"
                                        />
                                      ) : (
                                        <img
                                          // src={require(`../assets/images/${logo}`)}
                                          src={require(`../assets/images/${
                                            new Date(
                                              Number(toROI) * 1000
                                            ).getTime() > new Date().getTime()
                                              ? logo
                                              : "user_red_inac.png"
                                          }`)}
                                          id={
                                            "user_three" +
                                            index2 +
                                            subindex1 +
                                            userid
                                          }
                                          onClick={() => handleClick(userid)}
                                          className={
                                            new Date(
                                              Number(toROI) * 1000
                                            ).getTime() > new Date().getTime()
                                              ? "img-fluid"
                                              : "img-fluid isuser"
                                          }
                                          alt="Icon"
                                        />
                                      )}

                                      <ReactTooltip
                                        anchorId={
                                          "user_three" +
                                          index2 +
                                          subindex1 +
                                          userid
                                        }
                                        place="bottom"
                                        content={
                                          <p className="tooltip_data text-center myIdde">
                                            ID {userid}
                                          </p>
                                        }
                                      />
                                    </div>
                                  );
                                })}
                            </div>
                          );
                        })}
                    </div>

                    {/* Third Row */}
                    <div className="plan_pool_third_row">
                      {thirdRow &&
                        thirdRow.length > 0 &&
                        thirdRow.map((item3, index3) => {
                          return (
                            <div>
                              {item3 &&
                                item3 &&
                                item3.length > 0 &&
                                item3.map((item4, index4) => {
                                  var logo1 = getTreeImage(index3, item4);
                                  var index = userdetail3.findIndex(
                                    (val) =>
                                      val.address &&
                                      val.address.toLowerCase() ===
                                        item4.toLowerCase()
                                  );
                                  var userid = 0;
                                  var level = 0;
                                  var toROI = 0;
                                  if (index !== -1) {
                                    userid = userdetail3[index].id;
                                    level = userdetail3[index].currentLevel;
                                    toROI = userdetail3[index].roiTo;
                                  }
                                  return (
                                    <div>
                                      <div
                                        id={item4}
                                        className="plan_pool_head_child"
                                        data-tip
                                        data-for={item4}
                                      >
                                        {userid == 0 ? (
                                          <img
                                            src={require(`../assets/images/user_deac.png`)}
                                            onClick={() => handleClick(userid)}
                                            style={{ width: "45px" }}
                                            id={
                                              "user_four" +
                                              index3 +
                                              index4 +
                                              userid
                                            }
                                            className={"img-fluid"}
                                            alt="Icon"
                                          />
                                        ) : (
                                          <img
                                            src={require(`../assets/images/${
                                              new Date(
                                                Number(toROI) * 1000
                                              ).getTime() > new Date().getTime()
                                                ? logo1
                                                : "user_red_inac.png"
                                            }`)}
                                            onClick={() => handleClick(userid)}
                                            style={{ width: "45px" }}
                                            id={
                                              "user_four" +
                                              index3 +
                                              index4 +
                                              userid
                                            }
                                            className={
                                              new Date(
                                                Number(toROI) * 1000
                                              ).getTime() > new Date().getTime()
                                                ? "img-fluid"
                                                : "img-fluid isuser"
                                            }
                                            alt="Icon"
                                          />
                                        )}

                                        <ReactTooltip
                                          anchorId={
                                            "user_four" +
                                            index3 +
                                            index4 +
                                            userid
                                          }
                                          place="top"
                                          content={
                                            <p className="tooltip_data text-center myIdde">
                                              ID {userid}
                                            </p>
                                          }
                                        />
                                      </div>
                                      {/* ); */}
                                      {/* })} */}
                                    </div>
                                  );
                                })}
                            </div>
                          );
                        })}
                    </div>

                    {/* Fourth Row */}
                    <div className="plan_pool_fourth_row">
                      {forthRow &&
                        forthRow.length > 0 &&
                        forthRow.map((item5, index5) => {
                          return (
                            <div>
                              {item5 &&
                                item5 &&
                                item5.length > 0 &&
                                item5.map((item6, index6) => {
                                  var logo1 = getTreeImage(index5, item6);
                                  var index = userdetail4.findIndex(
                                    (val) =>
                                      val.address &&
                                      val.address.toLowerCase() ===
                                        item6.toLowerCase()
                                  );
                                  var userid = 0;
                                  var level = 0;
                                  var toROI = 0;
                                  if (index !== -1) {
                                    userid = userdetail4[index].id;
                                    toROI = userdetail4[index].roiTo;
                                  }
                                  return (
                                    <div>
                                      <div
                                        id={item6}
                                        className="plan_pool_head_child"
                                        data-tip
                                        data-for={item6}
                                      >
                                        {userid == 0 ? (
                                          <img
                                            src={require(`../assets/images/user_deac.png`)}
                                            onClick={() => handleClick(userid)}
                                            id={
                                              "user_five" +
                                              index5 +
                                              index6 +
                                              userid
                                            }
                                            className={"img-fluid"}
                                            alt="Icon"
                                          />
                                        ) : (
                                          <img
                                            src={require(`../assets/images/${
                                              new Date(
                                                Number(toROI) * 1000
                                              ).getTime() > new Date().getTime()
                                                ? logo1
                                                : "user_red_inac.png"
                                            }`)}
                                            onClick={() => handleClick(userid)}
                                            id={
                                              "user_five" +
                                              index5 +
                                              index6 +
                                              userid
                                            }
                                            className={
                                              new Date(
                                                Number(toROI) * 1000
                                              ).getTime() > new Date().getTime()
                                                ? "img-fluid"
                                                : "img-fluid isuser"
                                            }
                                            alt="Icon"
                                          />
                                        )}

                                        <ReactTooltip
                                          anchorId={
                                            "user_five" +
                                            index5 +
                                            index6 +
                                            userid
                                          }
                                          place="top"
                                          content={
                                            <p className="tooltip_data text-center myIdde">
                                              ID {userid}
                                            </p>
                                          }
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </Scrollbars>
              )}
            </div>
            <div className="bg_box mb-4 history">
              <h3 className="mb-4">Deposit History</h3>

              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col"> Time </th>
                      <th scope="col">Amount</th>
                      <th scope="col">Action</th>
                      <th scope="col">User ID</th>
                      <th scope="col">Address</th>
                    </tr>
                  </thead>
                  <tbody>
                    {latesttrx &&
                      latesttrx.length > 0 &&
                      latesttrx.map((item) => (
                        <>
                          <tr>
                            <td>
                              {/* {item.hours > 0 ? item.hours : item.min}{" "}
                              {item.hours > 0 ? " Hours ago" : " Min ago"} */}
                              {moment(timestampToIsoDate(item.date)).format(
                                "lll"
                              )}
                            </td>
                            <td>{item.value ? item.value : 0} </td>
                            <td>
                              {item.event == "regUser" ? "Join" : "Rejoin"}
                            </td>
                            <td>ID {item?.userid}</td>
                            <td>{truncateAddress(item.address, 8, 4)} </td>
                          </tr>
                        </>
                      ))}
                  </tbody>
                </table>
                {depoHisLoad && (
                  <svg
                    class="pl"
                    viewBox="0 0 128 128"
                    width="128px"
                    height="128px"
                  >
                    <defs>
                      <linearGradient id="pl-grad" x1="0" y1="0" x2="1" y2="1">
                        <stop offset="0%" stop-color="#000" />
                        <stop offset="100%" stop-color="#fff" />
                      </linearGradient>
                      <mask id="pl-mask">
                        <rect
                          x="0"
                          y="0"
                          width="128"
                          height="128"
                          fill="url(#pl-grad)"
                        />
                      </mask>
                    </defs>
                    <g
                      stroke-linecap="round"
                      stroke-width="8"
                      stroke-dasharray="32 32"
                    >
                      <g stroke="hsl(193,90%,50%)">
                        <line class="pl__line1" x1="4" y1="48" x2="4" y2="80" />
                        <line
                          class="pl__line2"
                          x1="19"
                          y1="48"
                          x2="19"
                          y2="80"
                        />
                        <line
                          class="pl__line3"
                          x1="34"
                          y1="48"
                          x2="34"
                          y2="80"
                        />
                        <line
                          class="pl__line4"
                          x1="49"
                          y1="48"
                          x2="49"
                          y2="80"
                        />
                        <line
                          class="pl__line5"
                          x1="64"
                          y1="48"
                          x2="64"
                          y2="80"
                        />
                        <g transform="rotate(180,79,64)">
                          <line
                            class="pl__line6"
                            x1="79"
                            y1="48"
                            x2="79"
                            y2="80"
                          />
                        </g>
                        <g transform="rotate(180,94,64)">
                          <line
                            class="pl__line7"
                            x1="94"
                            y1="48"
                            x2="94"
                            y2="80"
                          />
                        </g>
                        <g transform="rotate(180,109,64)">
                          <line
                            class="pl__line8"
                            x1="109"
                            y1="48"
                            x2="109"
                            y2="80"
                          />
                        </g>
                        <g transform="rotate(180,124,64)">
                          <line
                            class="pl__line9"
                            x1="124"
                            y1="48"
                            x2="124"
                            y2="80"
                          />
                        </g>
                      </g>
                      <g stroke="hsl(283,90%,50%)" mask="url(#pl-mask)">
                        <line class="pl__line1" x1="4" y1="48" x2="4" y2="80" />
                        <line
                          class="pl__line2"
                          x1="19"
                          y1="48"
                          x2="19"
                          y2="80"
                        />
                        <line
                          class="pl__line3"
                          x1="34"
                          y1="48"
                          x2="34"
                          y2="80"
                        />
                        <line
                          class="pl__line4"
                          x1="49"
                          y1="48"
                          x2="49"
                          y2="80"
                        />
                        <line
                          class="pl__line5"
                          x1="64"
                          y1="48"
                          x2="64"
                          y2="80"
                        />
                        <g transform="rotate(180,79,64)">
                          <line
                            class="pl__line6"
                            x1="79"
                            y1="48"
                            x2="79"
                            y2="80"
                          />
                        </g>
                        <g transform="rotate(180,94,64)">
                          <line
                            class="pl__line7"
                            x1="94"
                            y1="48"
                            x2="94"
                            y2="80"
                          />
                        </g>
                        <g transform="rotate(180,109,64)">
                          <line
                            class="pl__line8"
                            x1="109"
                            y1="48"
                            x2="109"
                            y2="80"
                          />
                        </g>
                        <g transform="rotate(180,124,64)">
                          <line
                            class="pl__line9"
                            x1="124"
                            y1="48"
                            x2="124"
                            y2="80"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
