import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import Web3 from "web3";
import config from "../lib/config";
import { toast } from "react-toastify";
import { connection } from "../helper/connection";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import polyfundcontractabi from "../ABI/polyfundcontractabi.json";
import usdtcontractABI from "../ABI/usdtcontractABI.json";
import copy from "copy-to-clipboard";
import { useNavigate } from "react-router-dom";

export default function Joinnow() {
  let toasterOption = config.toasterOption;
  const navigate = useNavigate();
  const walletConnection = useSelector((state) => state.walletConnection);
  var { id } = useParams();
  const [referaladdress, setreferaladdress] = useState("");
  const [progress, setprogress] = useState(false);

  useEffect(() => {
    getdetails();
    checkreferal();
  }, [walletConnection.address]);

  async function getdetails() {
    if (
      walletConnection &&
      walletConnection.address &&
      walletConnection.address != undefined &&
      walletConnection.address != null &&
      walletConnection.address != ""
    ) {
      if (
        walletConnection.userdetails &&
        walletConnection.userdetails.isExist
      ) {
        navigate("/dashboard");
      }
    }
  }

  async function checkreferal() {
    if (id && id != null && id != undefined && id != "") {
      setreferaladdress(id);
    }
  }

  const referralchange = (e) => {
    setreferaladdress(e.target.value);
  };

  async function empty() {}

  async function joinnow() {
    try {
      setprogress(true);
      if (
        walletConnection &&
        walletConnection.address &&
        walletConnection.address != undefined &&
        walletConnection.address != null &&
        walletConnection.address != ""
      ) {
        if (
          walletConnection.userdetails &&
          walletConnection.userdetails.isExist
        ) {
          setprogress(false);
          toast.error("Already Joined", toasterOption);
          navigate("/dashboard");
        } else {
          if (
            referaladdress.toLowerCase() !=
            walletConnection.address.toLowerCase()
          ) {
            let web3 = new Web3(
              walletConnection &&
              walletConnection.provider &&
              walletConnection.provider != null &&
              walletConnection.provider != undefined &&
              walletConnection.provider != ""
                ? walletConnection.provider
                : window.ethereum
            );
            console.log(
              config.polyfundcontract,
              referaladdress,
              walletConnection.address,
              "config.polyfundcontract"
            );
            if (web3.utils.isAddress(referaladdress)) {
              var polygonContract = new web3.eth.Contract(
                polyfundcontractabi,
                config.polyfundcontract
              );
              let userdetails = await polygonContract.methods
                .users(referaladdress)
                .call();
              if (userdetails.isExist) {
                var USDTcontract = new web3.eth.Contract(
                  usdtcontractABI,
                  config.USDTContractAddress
                );

                let Tokenbalnce = await USDTcontract.methods
                  .balanceOf(walletConnection.address)
                  .call();
                let joinamt = await polygonContract.methods.joinAmount().call();
                var amt = await convert(joinamt);

                console.log(
                  amt / config.decimals,
                  Tokenbalnce / config.decimals,
                  "amtamtamt"
                );
                if (Tokenbalnce / config.decimals >= amt / config.decimals) {
                  let estimateGas1 = await USDTcontract.methods
                    .approve(config.polyfundcontract, amt.toString())
                    .estimateGas({
                      from: walletConnection.address
                    })
                    .catch((err) => {
                      console.log(err, "sdfvdsgd");
                    });

                  var BNBbalance = await web3.eth.getBalance(
                    walletConnection.address
                  );
                  var gasPricebusd = await web3.eth.getGasPrice();
                  console.log(
                    (estimateGas1 * gasPricebusd) / 1e18,
                    BNBbalance / 1e18,
                    "estimateGas > BNBbalance",
                    gasPricebusd
                  );

                  if (
                    (estimateGas1 * gasPricebusd) / 1e18 >
                    BNBbalance / 1e18
                  ) {
                    setprogress(false);
                    toast.error("Your BNB balance is low", toasterOption);
                    return false;
                  }
                  await USDTcontract.methods
                    .approve(config.polyfundcontract, amt.toString())
                    .send({ from: walletConnection.address })
                    .then(async (result) => {
                      console.log(result, "result======");
                      if (result && result.status && result.transactionHash) {
                        let estimateGas = await polygonContract.methods
                          .regUser(referaladdress, amt.toString())
                          .estimateGas({
                            from: walletConnection.address
                          });

                        console.log(estimateGas, "estimateGas====");

                        let register = await polygonContract.methods
                          .regUser(referaladdress, amt.toString())
                          .send({
                            from: walletConnection.address
                          });
                        if (
                          register &&
                          register.status &&
                          register.transactionHash
                        ) {
                          setprogress(false);
                          toast.success("Joined Successfully", toasterOption);
                          navigate("/dashboard");
                        } else {
                          setprogress(false);
                          toast.error(
                            "Failed , try again later",
                            toasterOption
                          );
                        }
                      }
                    })
                    .catch((error) => {
                      console.log(error, "error");
                      toast.error("Approve failed", toasterOption);
                      setprogress(false);
                    });
                } else {
                  setprogress(false);
                  toast.error("USDT balance is low", toasterOption);
                }
              } else {
                setprogress(false);
                toast.error("Referral user not exist", toasterOption);
              }
            } else {
              if (id && id != null && id != undefined && id != "") {
                setprogress(false);
                toast.error("Invalid Referral Link", toasterOption);
              } else {
                setprogress(false);
                toast.error("Please Enter valid Address", toasterOption);
              }
            }
          } else {
            setprogress(false);
            toast.error(
              "Your Wallet and Referral both are same",
              toasterOption
            );
          }
        }
      } else {
        setprogress(false);
        toast.error("Please connect BNB network", toasterOption);
      }
    } catch (err) {
      console.log(err, "errrrrrrmuvitha");
      setprogress(false);
      toast.error("Failed , try again later", toasterOption);
    }
  }

  function convert(n) {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n
      .toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    return +pow < 0
      ? sign +
          "0." +
          "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
          lead +
          decimal
      : sign +
          lead +
          (+pow >= decimal.length
            ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
            : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
  }

  async function copyToClipboard(text) {
    // setreferaladdress(text);
    // navigator.clipboard.writeText(text);
    copy(text);
    toast.success("Copied Successfully", toasterOption);
  }

  return (
    <div>
      <Navbar />
      <div className="dashboard joinnow">
        <div className="dashboard_shade">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mx-auto">
                <h3 className="text-center mb-4">Join now</h3>
                <div className="bg_box tree mb-4 d-flex justify-content-center">
                  <div className="w-100">
                    <label className="mb-2"> Referral ID </label>
                    <div className="t_flx">
                      <div class="input-group w-100">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Referral ID"
                          value={referaladdress}
                          onChange={referralchange}
                          disabled={
                            id && id != undefined && id != null && id != ""
                              ? "disabled"
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="d-flex mt-3 align-items-center">
                      <p
                        className="mb-0 me-2 text-break"
                        style={{ fontSize: "15px" }}
                      >
                        Default ID : {config.ownerWallet}
                      </p>
                      {id ? (
                        <></>
                      ) : (
                        <img
                          src={require("../assets/images/copy.png")}
                          className="img-fluid"
                          style={{ cursor: "pointer", height: "15px" }}
                          onClick={() => {
                            copyToClipboard(config.ownerWallet);
                          }}
                        />
                      )}
                    </div>
                    <span style={{ fontSize: "13px" }}>
                      ( Note : You have no referrals use default id )
                    </span>
                    <div className="text-center">
                      <button
                        className="primary_btn mt-4"
                        onClick={
                          progress == false ? () => joinnow() : () => empty()
                        }
                      >
                        {progress == false
                          ? "Submit"
                          : "Processing..Please Wait.."}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
