import React from "react";
import config from "../lib/config";

export default function Footer(props) {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_panel">
          <p>Smart Contract </p>
          <a
            href={`${config.scan}address/${config.polyfundcontract}`}
            target="_blank"
            style={{ color: "white" }}
            className="text-break"
          >
            {config.polyfundcontract}
          </a>
          <p className="blue">
            Support :{" "}
            <a
              href="https://chat.whatsapp.com/DLsglddiUMg8J4kDCaqlKz"
              target="_blank"
              className="blue"
            >
              Whatsapp Group
            </a>
          </p>
          <div className="footer_panel_right">
            <ul className="footer_social_links">
              <li>
                <a href="https://t.me/unityfundio" target="_blank">
                  <i className="fab fa-telegram-plane"></i>
                </a>
              </li>
              <li>
                <a href="https://t.me/unityfundteam" target="_blank">
                  <i className="fab fa-telegram-plane"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://chat.whatsapp.com/C7j1sKhT1o28b0er7g2D5f"
                  target="_blank"
                >
                  <i className="fab fa-whatsapp"></i>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/unityfundio" target="_blank">
                  <i className="fa-brands fa-x-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://youtu.be/q8DLQq54dNs?si=mM3IkZxhKCMw1IqI"
                  target="_blank"
                >
                  <i className="fa-brands fa-youtube"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
